import {ESource} from '@/_types/appPage';

export const SOURCE_OPTIONS: ReadonlyArray<{value: ESource; name: string}> = [
	{
		value: ESource.Itunes,
		name: 'App Store',
	},
	{
		value: ESource.GooglePlay,
		name: 'Google Play',
	},
];
