import React, {Ref} from 'react';
import cn from 'classnames';

import css from './Input.module.css';

export type TProps = {
	value: string | number | undefined;
	placeholder?: string;
	type?: string;
	disabled?: boolean;
	error?: boolean;
	className?: string;
	icon?: React.ReactElement<{className: string}>;
	inputClassName?: string;
	onChange?: (value: string) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'placeholder' | 'ref'>;

function Input(
	{value, onChange, error, type, className, placeholder, inputClassName, icon, ...props}: TProps,
	ref?: Ref<HTMLInputElement>,
) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.(e.currentTarget.value);
	};

	return (
		<div className={cn(css.root, className)}>
			{icon && React.cloneElement(icon, {className: css.icon})}
			<input
				{...props}
				value={value}
				className={cn(
					css.input,
					icon && css['with-icon'],
					error && css['is-invalid'],
					inputClassName,
				)}
				placeholder={placeholder?.toString()}
				onChange={handleChange}
				ref={ref}
			/>
		</div>
	);
}

export default React.memo(React.forwardRef(Input));
