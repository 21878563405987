import React, {CSSProperties} from 'react';
import classNames from 'classnames';

import css from './Space.module.css';

interface IProps {
	children: React.ReactNode;
	gap?: 'xxxxs' | 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl';
	direction?: 'vertical' | 'horizontal';
	className?: string;
	align?: 'left' | 'right' | 'center';
	style?: CSSProperties;
}

const Space = React.forwardRef(function Space(
	{className, gap = 'xs', direction = 'horizontal', align = 'left', children, ...props}: IProps,
	ref?: React.Ref<HTMLDivElement>,
) {
	return (
		<div
			ref={ref}
			{...props}
			className={classNames(
				css.root,
				direction === 'vertical' && css.vertical,
				gap === 'xxxxs' && css.xxxxs,
				gap === 'xxxs' && css.xxxs,
				gap === 'xxs' && css.xxs,
				gap === 'xs' && css.xs,
				gap === 'sm' && css.sm,
				gap === 'md' && css.md,
				gap === 'lg' && css.lg,
				gap === 'xl' && css.xl,
				gap === 'xxl' && css.xxl,
				gap === 'xxxl' && css.xxxl,
				gap === 'xxxxl' && css.xxxxl,
				align === 'left' && css.alignLeft,
				align === 'right' && css.alignRight,
				align === 'center' && css.alignCenter,
				className,
			)}
		>
			{children}
		</div>
	);
});

export default React.memo(Space);
