import React, {FC} from 'react';

import {Spinner} from '@/_components/ui-kit/Spinner';

import css from './SelectLoading.module.css';

const SelectLoading: FC = () => (
	<span className={css.root}>
		<Spinner size="sm" />
		<span className={css['loading-text']}>Loading</span>
	</span>
);

export default SelectLoading;
