import {makeAssetUrl} from '@/_utils/makeAssetUrl';

export const NAV_LINKS: ReadonlyArray<{
	menuElement: string;
	menuTitle: string;
	menuItems?: ReadonlyArray<{
		link: string;
		title: string;
		text?: string;
		label?: 'hot' | 'new';
	}>;
	link?: string;
}> = [
	{
		menuElement: 'features',
		menuTitle: 'Platform',
		menuItems: [
			{
				link: 'https://appfollow.io/monitor-app-performance',
				title: 'App Monitor',
				text: 'Discover areas for improvement and differentiation from user feedback and competitor insights.',
			},
			{
				link: 'https://appfollow.io/aso',
				title: 'ASO Tools',
				text: 'Harness valuable market intelligence for targeted messaging and better organic reach.',
			},
			{
				link: 'https://appfollow.io/app-review-management-tool',
				title: 'AI-Powered Review Management',
				text: 'Take the next step in how your business engages with user reviews.',
			},
			{
				link: 'https://appfollow.io/customer-support-automation-tool',
				title: 'Workflow Automation',
				text: 'Respond to reviews, sort and report offensive ones automatically. Streamline your support workflows.',
			},
			{
				link: 'https://appfollow.io/integrations',
				title: 'Integrations',
				text: 'Process critical app data on reviews, ratings, and ASO in email, Slack, Zendesk, Tableau, Webhook, and over 20 more services.',
			},
		],
	},
	{
		menuElement: 'enterprise',
		menuTitle: 'Enterprise',
		link: 'https://appfollow.io/enterprise',
	},
	{
		menuElement: 'asoconsulting',
		menuTitle: 'Consultancy',
		link: 'https://appfollow.io/asoconsulting',
	},
	{
		menuElement: 'customers',
		menuTitle: 'Customers',
		link: 'https://appfollow.io/customers',
	},
	{
		menuElement: 'resources',
		menuTitle: 'Resources',
		menuItems: [
			{
				link: 'https://appfollow.io/blog',
				title: 'Blog',
				text: 'Explore expert articles and case studies on app growth, ASO, user retention, and mobile SaaS trends.',
			},
			{
				link: 'https://support.appfollow.io',
				title: 'Help Center',
				text: 'Find expert tips and solutions for utilizing AppFollow to its maximum potential.',
			},
			{
				link: 'https://appfollow.io/webinars',
				title: 'Webinars',
				text: 'Attend insightful webinars covering mobile app reputation, customer satisfaction, ASO, and more.',
			},
			{
				link: 'https://get.appfollow.io/learning-hub',
				title: 'E-books, guides & more',
				text: 'Enhance your app reputation management skills with our collection of valuable resources.',
			},
			{
				link: 'https://appfollow.io/news',
				title: 'News',
				text: "Stay updated on AppFollow's latest news, events, interviews, and feature releases.",
			},
			{
				link: 'https://get.appfollow.io/appfollow-api',
				title: 'API for developers',
				text: 'Leverage our API to seamlessly integrate AppFollow with other services.',
			},
			{
				link: 'https://appfollow.io/academy',
				title: 'Academy',
				text: 'Master your mobile marketing goals with comprehensive ASO and review management tutorials.',
			},
		],
	},
	{
		menuElement: 'pricing',
		menuTitle: 'Pricing',
		link: 'https://appfollow.io/pricing',
	},
];

export const FOOTER_LINKS: ReadonlyArray<{
	blockTitle: string;
	blockItems: ReadonlyArray<{
		link: string;
		title: string;
		blank?: boolean;
		nowrap?: boolean;
	}>;
}> = [
	{
		blockTitle: 'Company',
		blockItems: [
			{link: 'https://appfollow.io/about', title: 'About'},
			{link: 'https://appfollow.io/pricing', title: 'Pricing'},
			{link: 'https://appfollow.io/asoconsulting', title: 'Consultancy'},
			{link: 'https://appfollow.io/customers', title: 'Customers'},
			{link: 'https://appfollow.io/testimonials', title: 'Testimonials'},
			{link: 'https://appfollow.io/team', title: 'Team'},
			{
				link: 'https://appfollow.io/contact',
				title: 'Contact us',
			},
			{link: 'https://appfollow.io/partners', title: 'For partners'},
		],
	},
	{
		blockTitle: 'Platform',
		blockItems: [
			{
				link: 'https://appfollow.io/monitor-app-performance',
				title: 'App Monitor',
				nowrap: true,
			},
			{
				link: 'https://appfollow.io/aso',
				title: 'ASO Tools',
				nowrap: true,
			},
			{
				link: 'https://appfollow.io/app-review-management-tool',
				title: 'AI Review Management',
				nowrap: true,
			},
			{
				link: 'https://appfollow.io/customer-support-automation-tool',
				title: 'Workflow Automation',
				nowrap: true,
			},
			{link: 'https://appfollow.io/consoles', title: 'Consoles Data Aggregator'},
			{
				link: 'https://get.appfollow.io/app-competitor-analysis',
				title: 'App Competitor Analysis',
			},
			{
				link: 'https://get.appfollow.io/product-roadmaps-for-mobile-app-market',
				title: 'Product Roadmaps',
			},
			{link: 'https://appfollow.io/rankings', title: 'Top Chart Rankings'},
		],
	},
	{
		blockTitle: 'Solutions',
		blockItems: [
			{
				link: 'https://appfollow.io/customer-support',
				title: 'Customer support',
			},
			{link: 'https://appfollow.io/marketing', title: 'ASO & marketing'},
			{link: 'https://appfollow.io/product-managers', title: 'Product managers'},
			{link: 'https://appfollow.io/games', title: 'Gaming companies'},
			{link: 'https://appfollow.io/enterprise', title: 'For Enterprise'},
		],
	},
	{
		blockTitle: 'Resources',
		blockItems: [
			{link: 'https://appfollow.io/blog', title: 'Blog'},
			{link: 'https://appfollow.io/webinars', title: 'Webinars'},
			{link: 'https://appfollow.io/academy', title: 'ASO Academy'},
			{link: 'https://appfollow.io/reviews-management-academy', title: 'Reviews academy'},
			{link: 'https://help.appfollow.io/', title: 'Help Center'},
			{link: 'https://get.appfollow.io/appfollow-api', title: 'API'},
			{link: 'https://appfollow.io/free-aso-tools', title: 'Free ASO Tools'},
			{link: 'https://get.appfollow.io/learning-hub', title: 'E-books, guides & more'},
		],
	},
	{
		blockTitle: 'How we compare',
		blockItems: [
			{
				link: 'https://appfollow.io/compare/review-management',
				title: 'AppFollow vs Review Management Tools',
				nowrap: true,
			},
			{
				link: 'https://appfollow.io/compare/aso-tools',
				title: 'AppFollow vs other ASO Tools',
				nowrap: true,
			},
		],
	},
	{
		blockTitle: 'Integrations',
		blockItems: [
			{link: 'https://appfollow.io/salesforce', title: 'Salesforce'},
			{link: 'https://appfollow.io/zendesk', title: 'Zendesk'},
			{link: 'https://appfollow.io/tableau', title: 'Tableau'},
			{
				link: 'https://get.appfollow.io/discord-community-stats-and-semantics',
				title: 'Discord',
			},
			{link: 'https://appfollow.io/helpshift', title: 'Helpshift'},
			{link: 'https://appfollow.io/integrations', title: 'More'},
		],
	},
];

export const COPYRIGHT_CONFIG: ReadonlyArray<{
	url: string;
	name: string;
	imgSrc?: string;
}> = [
	{url: 'https://status.appfollow.io', name: 'Status'},
	{url: 'https://appfollow.io/terms', name: 'Terms'},
	{url: 'https://appfollow.io/privacy', name: 'Privacy'},
	{
		url: 'https://appfollow.io/contact',
		name: 'hi@appfollow.io',
		imgSrc: makeAssetUrl('/assets/site/img/email-hi.svg'),
	},
];
