'use client';

import React, {SyntheticEvent, useCallback} from 'react';
import cn from 'classnames';

import {Flag} from '@/_components/ui-kit/Flag';
import {TCountryCode} from '@/_types/country';
import {APP_PAGE_COUNTRY_CODES, COUNTRIES} from '@/_constants/countries';
import {isCountryCode} from '@/_utils/isCountryCode';

import css from './SelectCountry.module.css';

interface IProps {
	country: TCountryCode;
	className?: string;
	onChange: (value: Readonly<{country: TCountryCode}>) => void;
}

export default React.memo<IProps>(function SelectCountry({className, country, onChange}) {
	const handleChange = useCallback(
		(event: SyntheticEvent<HTMLSelectElement>) => {
			const {value} = event.currentTarget;
			if (isCountryCode(value)) onChange({country: value});
		},
		[onChange],
	);

	return (
		<div className={cn(css.root, className)}>
			<Flag country={country} className={css.flag} />
			<select onChange={handleChange} className={css.select} value={country}>
				{APP_PAGE_COUNTRY_CODES.map((item) => (
					<option key={item} value={item} className={css.option}>
						{COUNTRIES[item]}
					</option>
				))}
			</select>
		</div>
	);
});
