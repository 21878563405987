import React from 'react';

function isRefObject<T>(ref: React.Ref<T> | undefined): ref is React.MutableRefObject<T> {
	return ref !== null && typeof ref === 'object' && 'current' in ref;
}

function fillRef<T>(ref: React.Ref<T> | undefined, node: T) {
	if (typeof ref === 'function') ref(node);
	// eslint-disable-next-line no-param-reassign
	else if (isRefObject(ref)) ref.current = node;
}

export function mergeRefs<T>(...refs: readonly (React.Ref<T> | undefined)[]): React.Ref<T> {
	return (node: T) => refs.forEach((ref) => fillRef(ref, node));
}
