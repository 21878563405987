'use client';

import React, {Ref, useCallback, useLayoutEffect, useRef, useState} from 'react';
import Image from 'next/image';

import {Input, TProps as InputProps} from '@/_components/ui-kit/Input';
import {EKeyboardEventCode} from '@/_types/keyboard';
import {Spinner} from '@/_components/ui-kit/Spinner';

export interface IProps extends Omit<InputProps, 'onChange'> {
	value: string;
	delay?: number;
	isLoading?: boolean;
	className?: string;
	onChange: (value: string) => void;
	onEnter?: (value: string) => void;
}

export default React.memo(
	React.forwardRef(function InputSearch(
		{value, delay, isLoading, onChange, onEnter, ...props}: IProps,
		ref?: Ref<HTMLInputElement>,
	) {
		const [search, setSearch] = useState(value);
		useLayoutEffect(() => setSearch(value), [value]);

		const changeTimer = useRef<number>();

		const changeHandler = useCallback(
			(changes: string) => {
				setSearch(changes);
				window.clearTimeout(changeTimer.current);
				changeTimer.current = window.setTimeout(() => onChange(changes), delay || 0);
			},
			[delay, changeTimer, onChange],
		);

		const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === EKeyboardEventCode.Enter) {
				onEnter?.(search);
			}
		};

		return (
			<Input
				{...props}
				ref={ref}
				value={search}
				icon={
					isLoading ? (
						<Spinner size="sm" />
					) : (
						<Image src="/img/search.svg" alt="search" width={20} height={20} />
					)
				}
				onChange={changeHandler}
				onKeyDown={handleKeyDown}
			/>
		);
	}),
);
