import {fetchApi} from '@/_utils/fetchApi';
import {EDevice, ESource, TApp, TRatingReviewsData, TSimilarApp} from '@/_types/appPage';
import {TCountryCode} from '@/_types/country';

export const loadAppPage = async (
	store: ESource,
	country: TCountryCode,
	extId: string,
	slug: string,
) => {
	return (
		await fetchApi.get<TApp>('https://app-pages-api.appfollow.io/api/v2/appdata', {
			store,
			ext_id: extId,
			slug,
			country,
		})
	).data;
};

export const loadDevApps = async (
	store: ESource,
	country: TCountryCode,
	developer: string,
	exclude: string,
) => {
	return (
		await fetchApi.get<{developer_apps: ReadonlyArray<TSimilarApp>}>(
			'https://app-pages-api.appfollow.io/api/v2/devapps',
			{
				store,
				country,
				developer,
				exclude,
			},
		)
	).data;
};

export const loadSimilarApps = async (store: ESource, country: TCountryCode, extId: string) => {
	return (
		await fetchApi.get<{related_apps: ReadonlyArray<TSimilarApp>}>(
			'https://app-pages-api.appfollow.io/api/v2/relapps',
			{
				store,
				country,
				ext_id: extId,
			},
		)
	).data;
};

export const loadReviews = async (
	store: ESource,
	country: TCountryCode,
	extId: string,
	slug: string,
) => {
	return (
		await fetchApi.get<TRatingReviewsData>('https://app-pages-api.appfollow.io/api/v2/reviews', {
			store,
			ext_id: extId,
			slug,
			country,
		})
	).data;
};

export const loadAppScore = async (
	store: ESource,
	country: TCountryCode,
	extId: string,
	slug: string,
) => {
	return (
		await fetchApi.get<{score: number | null}>('https://app-pages-api.appfollow.io/api/v2/score', {
			store,
			ext_id: extId,
			slug,
			country,
		})
	).data;
};

export const loadSearchApps = async (device: EDevice, country: TCountryCode, term: string) => {
	return (
		await fetchApi.get<{result?: ReadonlyArray<TApp>; error?: 0 | 1}>('/api/search', {
			device,
			term,
			country,
		})
	).data;
};
