'use client';

import React from 'react';
import ReactDom from 'react-dom';

interface IProps {
	className?: string;
	children: React.ReactNode;
}

export default React.memo<IProps>(function Portal({className, children}) {
	return ReactDom.createPortal(<div className={className}>{children}</div>, document.body);
});
