'use client';

import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import cn from 'classnames';
import {useParams, useRouter, useSearchParams} from 'next/navigation';
import slugify from 'slugify';

import {EDevice, ESource, TApp, TPageFilters, TPageParams} from '@/_types/appPage';
import {useToggle} from '@/_hooks/useToggle';
import {SOURCE_OPTIONS} from '@/_components/appPage/Filters/constants';
import {SelectSearchApp} from '@/_components/appPage/SelectSearchApp';
import {loadSearchApps} from '@/_services/appPage';
import {DEFAULT_COUNTRY_CODE} from '@/_constants/countries';
import {SelectCountry} from '@/_components/appPage/SelectCountry';
import {TCountryCode} from '@/_types/country';
import {stringifySearchParams} from '@/_utils/stringifySearchParams';

import css from './Filters.module.css';

interface IProps {
	className?: string;
}

export default React.memo<IProps>(function Filters({className}) {
	const router = useRouter();
	const params = useParams<Partial<TPageParams>>();
	const searchParams = useSearchParams();

	const [filters, setFilters] = useState<Partial<TPageFilters>>({
		...params,
		source: params.source || ESource.Itunes,
		slug: params.slug || '',
		country: (searchParams.get('country') || DEFAULT_COUNTRY_CODE) as TCountryCode,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchApps, setSearchApps] = useState<ReadonlyArray<TApp>>([]);
	const [isShowMobileControls, toggleMobileControls] = useToggle(false);

	const handleApplyFilters = useCallback(
		(newFilters: TPageFilters) => {
			router.push(
				`/${newFilters.source}/${slugify(newFilters.slug.toLowerCase(), {strict: true})}/${
					newFilters.extId
				}/?${stringifySearchParams({
					country: newFilters.country,
				})}`,
			);
		},
		[router],
	);

	const handleChangeFilters = useCallback((newFilters: Partial<TPageFilters>) => {
		setFilters((prevState) => ({...prevState, ...newFilters}));
	}, []);

	const handleChangeSource = useCallback(
		(event: SyntheticEvent<HTMLButtonElement>) => {
			const {source: newSource} = event.currentTarget.dataset as {source?: ESource};
			handleChangeFilters({source: newSource ?? ESource.Itunes});
		},
		[handleChangeFilters],
	);

	const handleChangeApp = useCallback(
		(app: TApp) => {
			const newFilters = {slug: app.title, extId: app.ext_id};
			handleChangeFilters(newFilters);
			setSearchTerm(app.title);
			handleApplyFilters({
				...newFilters,
				country: filters.country ?? DEFAULT_COUNTRY_CODE,
				source: filters.source ?? ESource.Itunes,
			});
		},
		[handleChangeFilters, handleApplyFilters, filters.country, filters.source],
	);

	const handleChangeCountry = useCallback(
		(value: Readonly<{country: TCountryCode}>) => {
			handleChangeFilters(value);

			if (!filters.extId) return;

			handleApplyFilters({
				source: filters.source || ESource.Itunes,
				extId: filters.extId,
				slug: filters.slug || '',
				country: value.country,
			});
		},
		[handleChangeFilters, handleApplyFilters, filters.extId, filters.source, filters.slug],
	);

	useEffect(() => {
		if (searchTerm.trim()) {
			setIsLoading(true);
			loadSearchApps(
				filters.source === ESource.Itunes ? EDevice.Iphone : EDevice.Android,
				filters.country || DEFAULT_COUNTRY_CODE,
				searchTerm.trim(),
			)
				.then((response) => {
					if (response.result?.length) setSearchApps(response.result.filter((app) => !!app.title));
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	}, [searchTerm, filters.country, filters.source]);

	return (
		<section className={cn(css.root, className)}>
			<button onClick={toggleMobileControls} className={css['mobile-control']}>
				Search app
			</button>
			<div className={cn(css.controls, isShowMobileControls && css['controls-active'])}>
				<div className={css['source-wrap']}>
					{SOURCE_OPTIONS.map((option) => (
						<button
							key={option.value}
							data-source={option.value}
							type="button"
							className={cn(css.source, filters.source === option.value && css['source-active'])}
							onClick={handleChangeSource}
						>
							{option.name}
						</button>
					))}
				</div>
				<SelectSearchApp
					isLoading={isLoading}
					placeholder="Enter an app name"
					value={searchTerm}
					options={searchApps}
					className={css.input}
					source={filters.source ?? ESource.Itunes}
					onSearch={setSearchTerm}
					onChange={handleChangeApp}
				/>
				<SelectCountry
					country={filters.country ?? DEFAULT_COUNTRY_CODE}
					onChange={handleChangeCountry}
					className={css.country}
				/>
			</div>
		</section>
	);
});
