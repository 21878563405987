import {ReactElement} from 'react';

export function mergeOnClick(
	element: ReactElement<{onClick?: Function}>,
	clickHandler?: Function,
): Function {
	return (...args: unknown[]) => {
		element.props.onClick?.(...args);
		clickHandler?.();
	};
}
