import React from 'react';
import cn from 'classnames';

import {TCountryCode} from '@/_types/country';

import css from './Flag.module.css';

interface IProps {
	country: TCountryCode;
	className?: string;
}

const Flag = React.forwardRef(function Flag(
	{country, className, ...props}: IProps,
	ref?: React.Ref<HTMLElement>,
) {
	return <i ref={ref} {...props} className={cn(css.flag, css[country], className)} />;
});

export default React.memo(Flag);
