'use client';

import React, {useCallback, useMemo} from 'react';
import cn from 'classnames';

import {Popover} from '@/_components/ui-kit/Popover';
import {useToggle} from '@/_hooks/useToggle';
import {ScrollWrap} from '@/_components/ui-kit/ScrollWrap';
import {InputSearch} from '@/_components/ui-kit/InputSearch';
import {SelectLoading} from '@/_components/ui-kit/SelectLoading';
import {ESource, TApp} from '@/_types/appPage';
import {AppIcon} from '@/_components/ui-kit/AppIcon';
import {Space} from '@/_components/ui-kit/Space';

import css from './SelectSearchApp.module.css';

interface IProps {
	value: string;
	options: ReadonlyArray<TApp>;
	source: ESource;
	isLoading?: boolean;
	placeholder?: string;
	className?: string;
	onSearch: (value: string) => void;
	onChange: (option: TApp) => void;
}

export default React.memo<IProps>(function SelectSearchApp({
	options,
	value,
	source,
	onSearch,
	onChange,
	placeholder,
	isLoading,
	className,
}) {
	const [open, toggle, setState] = useToggle();
	const handleOpen = useCallback(() => {
		if (!options.length) return;
		toggle();
	}, [toggle, options.length]);

	const handleSearch = useCallback(
		(text: string) => {
			setState(true);
			onSearch(text);
		},
		[setState, onSearch],
	);
	const content = useMemo(() => {
		return (
			<div className={css.menu}>
				<ScrollWrap className={css.options}>
					{isLoading && (
						<div className={css.empty}>
							<SelectLoading />
						</div>
					)}
					{options.map((option) => (
						<button
							key={option.title}
							className={css.button}
							onClick={() => {
								onChange(option);
								toggle();
							}}
						>
							<Space className={css.option}>
								<AppIcon src={option.icon} size="sm" border={source === ESource.Itunes} />
								<span className={css.name}>{option.title}</span>
							</Space>
						</button>
					))}
					{!isLoading && !options.length && <div className={css.empty}>No data available</div>}
				</ScrollWrap>
			</div>
		);
	}, [isLoading, options, onChange, toggle, source]);

	return (
		<Popover visible={open} content={content} className={css.root} onClickOutside={toggle}>
			<button className={cn(css.trigger, className)} onClick={handleOpen}>
				<InputSearch
					delay={500}
					value={value}
					isLoading={isLoading}
					className={css.input}
					placeholder={placeholder}
					onChange={handleSearch}
				/>
			</button>
		</Popover>
	);
});
