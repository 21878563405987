import React from 'react';
import cn from 'classnames';

import css from './Spinner.module.css';

interface IProps {
	size?: 'xs' | 'sm' | 'md' | 'lg';
	inline?: boolean;
	className?: string;
}

export default React.memo(function Spinner({size, inline, className}: IProps) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			className={cn(
				css.root,
				inline && css.inline,
				size === 'xs' && css.xs,
				size === 'sm' && css.sm,
				size === 'lg' && css.lg,
				className,
			)}
		>
			<circle
				r="10"
				cx="12"
				cy="12"
				stroke="currentColor"
				strokeWidth="2.5"
				fill="none"
				className={css.circle}
			/>
		</svg>
	);
});
