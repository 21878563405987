import {TCountryCode} from '@/_types/country';

export enum ESource {
	Itunes = 'ios',
	GooglePlay = 'android',
}
export enum EDevice {
	Iphone = 'iphone',
	Android = 'android',
}

export type TScreenshots = Readonly<{
	id: string;
	list: ReadonlyArray<{full: string; view: string}>;
	name: string;
}>;

export type TReview = Readonly<{
	date: string;
	name: string;
	rating: number;
	text: string;
	developer_response?: Readonly<{
		date: string;
		text: string;
	}>;
}>;

export type TApp = Readonly<{
	title: string;
	description: string;
	subtitle: string;
	developer: string;
	categories: ReadonlyArray<string>;
	tags: ReadonlyArray<{label: string; active: boolean}>;
	reviews: ReadonlyArray<TReview>;
	screenshots: ReadonlyArray<TScreenshots>;
	icon: string;
	version: string;
	permalink: string;
	price: string;
	reviews_rating: number;
	reviews_total: string;
	developer_url: string;
	last_update: string;
	url: string;
	device: EDevice;
	ext_id: string;
	avg_rating?: number;
	avg_featured?: number;
	reviews_number_30_days?: number;
	isPlaceholder?: boolean;
}>;

export type TSimilarApp = Readonly<{
	categories: ReadonlyArray<string>;
	icon: string;
	title: string;
	url: string;
	reviews_rating?: number;
	reviews_total?: string;
}>;

export type TRatingReviewsData = Partial<
	Readonly<{
		reviews_total: number;
		reviews_number_30_days: number;
		avg_rating: number;
		avg_rating_5y: number;
		avg_featured: number;
		reply_rate: number;
		reply_rate_1m: number;
		featured_reviews: number;
		featured_reviews_1m: number;
		updated_reviews_1m: number;
	}>
>;

export type TPageParams = Readonly<{
	extId: string;
	slug: string;
	source: ESource;
}>;

export type TPageFilters = TPageParams & Readonly<{country: TCountryCode}>;
