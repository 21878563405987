import {ReactElement, Ref} from 'react';

export enum ETrigger {
	click = 'click',
	hover = 'hover',
}

export type TVerticalPos = 'top' | 'middle' | 'bottom';
export type THorizontalPos = 'left' | 'center' | 'right';

export type TPosition = Exclude<
	`${TVerticalPos}-${THorizontalPos}` | 'right-top' | 'right-bottom',
	'middle-center'
>;

export type TPopoverChild = ReactElement<{
	inactive?: boolean;
	loading?: boolean;
	onClick?: Function;
	onMouseEnter?: Function;
	onMouseLeave?: Function;
}> & {ref?: Ref<unknown>};
