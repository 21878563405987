import React from 'react';
import cn from 'classnames';

import {makeAssetUrl} from '@/_utils/makeAssetUrl';

interface IProps {
	isMobile?: boolean;
	className?: string;
}

export default function FooterCopyright({className, isMobile}: IProps) {
	return (
		<span
			className={cn(isMobile ? 'footer-mobile__copyright' : 'footer__bottom__copyright', className)}
		>
			© {new Date().getFullYear()}, Made with{' '}
			<img
				src={makeAssetUrl('/assets/2/img/raised_hands.png')}
				width="15"
				height="14"
				title="Made with hands"
				alt="hands"
			/>{' '}
			in Helsinki
		</span>
	);
}
