import {CSSProperties} from 'react';

import {TVerticalPos, THorizontalPos, TPosition} from './types';

type TElementRect = Readonly<{
	width: number;
	height: number;
	top: number;
	bottom: number;
	left: number;
	right: number;
}>;

function getRect(element: HTMLElement): TElementRect {
	const vOffset = window.scrollY;
	const rect = element.getBoundingClientRect();

	// Note: x, y & toJSON does not work in Edge 18
	return {
		width: rect.width,
		height: rect.height,
		top: rect.top + vOffset,
		bottom: rect.bottom + vOffset,
		left: rect.left,
		right: rect.right,
	};
}

export function getAnchorStyles(element: HTMLElement | null, position: TPosition): CSSProperties {
	if (!element) return {};
	const rect = getRect(element);

	switch (position) {
		case 'top-left':
		case 'top-center':
		case 'top-right':
			return {
				top: `${rect.top}px`,
				left: `${rect.left}px`,
				width: `${rect.width}px`,
			};
		case 'middle-left':
			return {
				top: `${rect.top}px`,
				left: `${rect.left}px`,
				height: `${rect.height}px`,
			};
		case 'right-top':
		case 'middle-right':
			return {
				top: `${rect.top}px`,
				left: `${rect.right}px`,
				height: `${rect.height}px`,
			};
		case 'right-bottom':
			return {
				top: `${rect.top}px`,
				left: `${rect.right}px`,
				height: `${rect.height}px`,
			};
		case 'bottom-left':
		case 'bottom-center':
		case 'bottom-right':
		default:
			return {
				top: `${rect.bottom}px`,
				left: `${rect.left}px`,
				width: `${rect.width}px`,
			};
	}
}

const HALF = 2;
const H_POS_ORDER: readonly THorizontalPos[] = ['left', 'center', 'right'];

function isInViewWidth(left: number, right: number, viewWidth: number): boolean {
	return !(left < 0 || right > viewWidth);
}

function isInViewHeight(top: number, height: number, windowHeight: number): boolean {
	return top + height < windowHeight;
}

function without(exclude: THorizontalPos, isMiddle: boolean) {
	return (item: THorizontalPos) => item !== exclude && (isMiddle ? item !== 'center' : true);
}

function calcSides(
	vPos: TVerticalPos,
	hPos: THorizontalPos,
	popupWidth: number,
	triggerLeft: number,
	triggerRight: number,
): [left: number, right: number] {
	if (vPos === 'middle') {
		if (hPos === 'left') return [triggerLeft - popupWidth, triggerLeft];
		if (hPos === 'right') return [triggerRight, triggerRight + popupWidth];
		throw new ReferenceError();
	} else {
		if (hPos === 'left') return [triggerLeft, triggerLeft + popupWidth];
		if (hPos === 'right') return [triggerRight - popupWidth, triggerRight];

		// hPos === 'center'
		const center = (triggerRight + triggerLeft) / HALF;
		const halfWidth = popupWidth / HALF;
		return [center - halfWidth, center + halfWidth];
	}
}

export function correctPosition(
	position: TPosition,
	trigger: HTMLElement,
	popup: HTMLElement,
	windowWidth: number,
	windowHeight: number,
): TPosition {
	const popRect = popup.getBoundingClientRect();

	const inViewWidth = isInViewWidth(popRect.left, popRect.right, windowWidth);
	const inViewHeight = isInViewHeight(popRect.top, popRect.height, windowHeight);

	if (inViewWidth && inViewHeight) return position;

	const {left, right} = trigger.getBoundingClientRect();
	let [vPos, hPos] = position.split('-') as [TVerticalPos, THorizontalPos];
	if (!inViewHeight && popRect.top >= popRect.height) {
		vPos = 'top';
	}
	if (!inViewWidth) {
		const order = H_POS_ORDER.filter(without(hPos, vPos === 'middle'));

		for (let i = 0; i < order.length; i++) {
			if (isInViewWidth(...calcSides(vPos, order[i], popRect.width, left, right), windowWidth)) {
				hPos = order[i];
				break;
			}
		}
	}
	return `${vPos}-${hPos}` as TPosition;
}
