import {EHttpStatus, TFetchMethod, TFetchOptions, TFetchResponse} from '@/_utils/fetchApi/types';
import {formatUrl} from '@/_utils/fetchApi/formatUrl';
import * as Sentry from '@sentry/nextjs';

const fetcher = <T = unknown>(
	method: TFetchMethod,
	url: string,
	params?: object,
	options: Partial<TFetchOptions> = {},
): Promise<TFetchResponse<T>> => {
	return new Promise((resolve, reject) => {
		fetch(url, {
			cache: 'no-cache',
			method,
			body: params ? JSON.stringify(params) : null,
			mode: 'cors',
			headers: options.headers,
		})
			.then((response) => {
				const fetchResponse: TFetchResponse = {
					url,
					status: response.status,
					statusText: response.statusText,
					data: null,
				};

				if (response.status === EHttpStatus.NoContent) {
					return resolve(fetchResponse as TFetchResponse<T>);
				}

				return response
					.json()
					.then((data: T) => {
						const respWithData: TFetchResponse<T> = {...fetchResponse, data};
						response.ok ? resolve(respWithData) : reject(respWithData);
					})
					.catch((error: unknown) => {
						reject({...fetchResponse, data: error});
					});
			})
			.catch((error) => {
				if (process.env.NODE_ENV === 'production') {
					Sentry.captureException(error);
				}
				reject(error);
			});
	});
};

const get = async <T>(url: string, params?: object, options: Partial<TFetchOptions> = {}) => {
	return fetcher<T>('GET', formatUrl(url, params), undefined, options);
};

const buildRequestMethod = (method: TFetchMethod) => {
	return <T = unknown>(url: string, params?: object, options: Partial<TFetchOptions> = {}) =>
		fetcher<T>(method, url, params, options);
};

export const fetchApi = {
	get,
	post: buildRequestMethod('POST'),
	patch: buildRequestMethod('PATCH'),
	destroy: buildRequestMethod('DELETE'),
	put: buildRequestMethod('PUT'),
};
