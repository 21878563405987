'use client';

import React, {useCallback, useRef, useState, useLayoutEffect, useEffect} from 'react';
import classNames from 'classnames';

import {Portal} from '@/_components/ui-kit/Portal';
import {debounce} from '@/_utils/debounce';
import {mergeRefs} from '@/_utils/react/mergeRefs';
import {mergeOnClick} from '@/_utils/react/mergeOnClick';
import {useClickOutside} from '@/_hooks/useClickOutside';

import {getAnchorStyles, correctPosition} from './utils';
import {ETrigger, TPosition, TPopoverChild} from './types';

import css from './Popover.module.css';

const SHOW_DELAY = 200;
const CLOSE_DELAY = 150;
const RESIZE_DELAY = 100;

const POS_TO_CLASS: Readonly<Record<TPosition, string>> = {
	'top-left': css['top-left'],
	'top-center': css['top-center'],
	'top-right': css['top-right'],
	'middle-left': css['middle-left'],
	'middle-right': css['middle-right'],
	'bottom-left': css['bottom-left'],
	'bottom-center': css['bottom-center'],
	'bottom-right': css['bottom-right'],
	'right-top': css['right-top'],
	'right-bottom': css['right-bottom'],
};

export interface IProps {
	children: TPopoverChild;
	content: React.ReactNode | (() => React.ReactNode);
	visible?: boolean;
	type?: 'popover' | 'balloon';
	inactive?: boolean;
	trigger?: keyof typeof ETrigger;
	position?: TPosition;
	isFixedPosition?: boolean;
	enterDelay?: number;
	leaveDelay?: number;
	hideOnScroll?: boolean;
	updateOnResize?: boolean;
	className?: string;
	sectionTestId?: string;
	onClickOutside?: (value: false) => void;
	renderContent?: () => React.ReactNode;
}

export default React.memo<IProps>(function Popover({
	visible,
	type = 'popover',
	inactive,
	trigger = 'hover',
	position = 'bottom-left',
	isFixedPosition,
	enterDelay = SHOW_DELAY,
	leaveDelay = CLOSE_DELAY,
	hideOnScroll,
	updateOnResize,
	content,
	className,
	onClickOutside,
	children,
	sectionTestId,
}: IProps) {
	const isControlled = typeof visible === 'boolean';
	const withHover = trigger === ETrigger.hover && !isControlled && !inactive;

	const hoverTimeout = useRef<number>();
	const triggerElementRef = useRef<HTMLElement>(null);
	const [dialogElement, setDialogElement] = useState<HTMLDivElement | null>(null);
	const [innerVisible, setInnerVisible] = useState(false);
	const [fixedPos, setFixedPos] = useState<TPosition | undefined>(
		isFixedPosition ? position : undefined,
	);
	const [windowSize, setWindowSize] = useState<[number, number]>([0, 0]);
	const [windowWidth, windowHeight] = windowSize;

	const handleSetDialogElement = useCallback((node: HTMLDivElement | null) => {
		if (node) setDialogElement(node);
	}, []);

	const showPopup = useCallback(() => setInnerVisible(true), []);

	const hidePopup = useCallback(() => {
		if (isControlled && onClickOutside) onClickOutside(false);
		setInnerVisible(false);
	}, [isControlled, onClickOutside]);

	const popupRef = useClickOutside<HTMLDivElement>(triggerElementRef, hidePopup);

	const onPointerEnter = useCallback(() => {
		window.clearTimeout(hoverTimeout.current);
		hoverTimeout.current = window.setTimeout(showPopup, enterDelay);
	}, [showPopup, enterDelay]);

	const onPointerLeave = useCallback(() => {
		window.clearTimeout(hoverTimeout.current);
		hoverTimeout.current = window.setTimeout(hidePopup, leaveDelay);
	}, [hidePopup, leaveDelay]);

	const isVisible = visible || innerVisible;

	useLayoutEffect(() => {
		if (isFixedPosition) return;
		setFixedPos(
			isVisible && dialogElement && triggerElementRef.current
				? correctPosition(
						position,
						triggerElementRef.current,
						dialogElement,
						windowWidth || window.innerWidth,
						windowHeight || window.innerHeight,
				  )
				: undefined,
		);
	}, [isVisible, position, windowWidth, dialogElement, windowHeight, isFixedPosition]);

	useLayoutEffect(() => {
		window.clearTimeout(hoverTimeout.current);
	}, [inactive]);

	useEffect(() => {
		const listen: boolean = !!hideOnScroll && isVisible;
		if (listen) document.addEventListener('scroll', hidePopup, {passive: true});

		return () => {
			if (listen) document.removeEventListener('scroll', hidePopup);
		};
	}, [hideOnScroll, isVisible, hidePopup]);

	useEffect(() => {
		if (!updateOnResize) return undefined;
		const debouncedUpdate = debounce(
			() => setWindowSize([window.innerWidth, window.innerHeight]),
			RESIZE_DELAY,
		);

		window.addEventListener('resize', debouncedUpdate, {passive: true});
		return () => window.removeEventListener('resize', debouncedUpdate);
	}, [updateOnResize]);

	return (
		<>
			{React.cloneElement(children, {
				ref: mergeRefs(triggerElementRef, children.ref),
				...{
					...(withHover && {
						onPointerEnter,
						onPointerLeave,
						onClick: mergeOnClick(children, hidePopup),
					}),
					...(trigger === ETrigger.click && !isControlled && !inactive && {onClick: showPopup}),
				},
			} as TPopoverChild)}
			{isVisible && !!content && (
				<Portal>
					<div
						ref={trigger === ETrigger.click || isControlled ? popupRef : undefined}
						className={css.root}
						style={getAnchorStyles(triggerElementRef.current, fixedPos || position)}
						{...(withHover && {onPointerEnter, onPointerLeave})}
					>
						<div
							data-testid={sectionTestId}
							role="dialog"
							ref={handleSetDialogElement}
							className={classNames(
								css.dialog,
								type === 'balloon' && css.balloon,
								POS_TO_CLASS[fixedPos || position],
								className,
							)}
						>
							{typeof content === 'function' ? content() : content}
						</div>
					</div>
				</Portal>
			)}
		</>
	);
});
