'use client';

import React, {useState} from 'react';
import cn from 'classnames';

import {COPYRIGHT_CONFIG, FOOTER_LINKS} from '@/_constants/navigation';
import {FooterCopyright} from '@/_components/layout/FooterCopyright';

interface IProps {
	className?: string;
}

export default function FooterMobile({className}: IProps) {
	const [activeItem, setActiveItem] = useState<string | null>(null);

	return (
		<div className={cn('footer-mobile', className)}>
			{FOOTER_LINKS.map((item, index) => (
				<div key={`footer_block_${index}`} className="footer-mobile-menu-wrapper">
					<div
						key={item.blockTitle}
						className={cn('footer-mobile-menu', activeItem === item.blockTitle && 'active')}
					>
						<div
							className="footer-mobile-menu__header"
							onClick={() =>
								setActiveItem((prevState) =>
									prevState === item.blockTitle ? null : item.blockTitle,
								)
							}
						>
							<span className="text--black text--bold">{item.blockTitle}</span>
							<span className="footer-mobile-menu__minus">—</span>
							<span className="footer-mobile-menu__plus">+</span>
						</div>
						<ul className="footer-mobile-menu-list" role="menu">
							{item.blockItems.map((blockItem) => (
								<li key={blockItem.title}>
									<a
										className="footer-mobile-menu-list__link"
										href={blockItem.link}
										target={blockItem.blank ? '_blank' : '_self'}
										rel={blockItem.blank ? 'noopener noreferrer' : undefined}
									>
										{blockItem.title}
									</a>
								</li>
							))}
						</ul>
					</div>
				</div>
			))}

			<div className="footer-mobile__bottom g--side-paddings">
				<div className="footer-mobile__bottom-column">
					{COPYRIGHT_CONFIG.map((item) => (
						<a
							key={item.url}
							href={item.url}
							rel="noopener noreferrer"
							target="_blank"
							className="footer-mobile__bottom__link footer__link ui-link ui-link--light"
						>
							{item.imgSrc ? (
								<img src={item.imgSrc} alt={item.name} className="footer__email-img" />
							) : (
								item.name
							)}
						</a>
					))}
				</div>
			</div>
			<FooterCopyright isMobile />
		</div>
	);
}
