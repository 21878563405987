'use client';

import React, {ImgHTMLAttributes, useEffect} from 'react';
import cn from 'classnames';
import lozad from 'lozad';

type TProps = {
	src: string;
	alt: string;
	className?: string;
} & ImgHTMLAttributes<HTMLImageElement>;

export default React.memo<TProps>(function Image({className, src, alt, ...props}) {
	useEffect(() => {
		lozad().observe();
	}, []);

	return <img {...props} data-src={src} alt={alt} className={cn('lozad', className)} />;
});
