import {useRef, useCallback, useEffect, RefObject} from 'react';

export const useClickOutside = <T extends HTMLElement>(
	targetRef: RefObject<HTMLElement>,
	onClickOutside: () => void,
) => {
	const refElement = useRef<T>(null);

	const handler = useCallback(
		(event: Event) => {
			if (
				targetRef.current !== null &&
				refElement.current !== null &&
				targetRef.current !== event.target &&
				!targetRef.current.contains(event.target as Node) &&
				!refElement.current.contains(event.target as Node)
			) {
				onClickOutside();
			}
		},
		[targetRef, refElement, onClickOutside],
	);

	useEffect(() => {
		document.addEventListener('mousedown', handler);
		document.addEventListener('touchstart', handler);

		return () => {
			document.removeEventListener('mousedown', handler);
			document.removeEventListener('touchstart', handler);
		};
	}, [handler]);

	return refElement;
};
