type TValue = string | number | boolean | undefined | null;
export type TParams<T = TValue> = Record<string, T | ReadonlyArray<T>>;

/**
 * @example stringifySearchParams({foo: '1', bar: [2, 3]});
 */
export function stringifySearchParams(params: TParams): string {
	return new URLSearchParams(
		Object.keys(params).reduce<[string, string][]>((acc, key) => {
			const value = params[key];

			const push = (val: TValue): void => {
				if (val !== undefined && val !== null) acc.push([key, val.toString()]);
			};

			value instanceof Array ? value.forEach(push) : push(value);

			return acc;
		}, []),
	).toString();
}
