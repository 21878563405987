'use client';

import React from 'react';
import cn from 'classnames';

import {signupClick} from '@/_utils/signup';

import css from './AddYourApp.module.css';

const signupBarClick = () => {
	signupClick('sign_up_second_bar');
};

interface IProps {
	className?: string;
}

export default React.memo<IProps>(function AddYourApp({className}) {
	return (
		<button className={cn(css.root, className)} onClick={signupBarClick}>
			<div className={css.content}>
				Find out how{' '}
				<span className={css.call}>
					<br className={css.br} />
					your app
				</span>{' '}
				performs <br className={css.br} /> in app stores
			</div>
		</button>
	);
});
