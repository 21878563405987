import {TCountryCode} from '@/_types/country';

export const DEFAULT_COUNTRY_CODE: TCountryCode = 'us';

export const COUNTRIES: Readonly<Record<TCountryCode, string>> = {
	ad: 'Andorra',
	af: 'Afghanistan',
	al: 'Albania',
	dz: 'Algeria',
	ao: 'Angola',
	ai: 'Anguilla',
	ag: 'Antigua and Barbuda',
	ar: 'Argentina',
	am: 'Armenia',
	au: 'Australia',
	at: 'Austria',
	az: 'Azerbaijan',
	bs: 'Bahamas',
	bd: 'Bangladesh',
	bh: 'Bahrain',
	bb: 'Barbados',
	by: 'Belarus',
	be: 'Belgium',
	bz: 'Belize',
	bj: 'Benin',
	bm: 'Bermuda',
	bt: 'Bhutan',
	bo: 'Bolivia',
	ba: 'Bosnia and Herzegovina',
	bw: 'Botswana',
	br: 'Brazil',
	vg: 'British Virgin Islands',
	bn: 'Brunei Darussalam',
	bg: 'Bulgaria',
	bf: 'Burkina Faso',
	kh: 'Cambodia',
	cm: 'Cameroon',
	ca: 'Canada',
	cv: 'Cape Verde',
	ky: 'Cayman Islands',
	td: 'Chad',
	cl: 'Chile',
	cn: 'China',
	co: 'Colombia',
	cg: 'Congo',
	cu: 'Cuba',
	cd: 'Congo, Democratic Republic of the',
	cr: 'Costa Rica',
	ci: "Cote d'Ivoire",
	hr: 'Croatia',
	cy: 'Cyprus',
	cz: 'Czech Republic',
	dk: 'Denmark',
	dm: 'Dominica',
	do: 'Dominican Republic',
	ec: 'Ecuador',
	eg: 'Egypt',
	zm: 'Zambia',
	sv: 'El Salvador',
	ee: 'Estonia',
	et: 'Ethiopia',
	fj: 'Fiji',
	fi: 'Finland',
	fr: 'France',
	ga: 'Gabon',
	gm: 'Gambia',
	ge: 'Georgia',
	de: 'Germany',
	gh: 'Ghana',
	gi: 'Gibraltar',
	gr: 'Greece',
	gd: 'Grenada',
	gt: 'Guatemala',
	gw: 'Guinea-Bissau',
	gy: 'Guyana',
	hn: 'Honduras',
	hk: 'Hong Kong',
	hu: 'Hungary',
	is: 'Iceland',
	in: 'India',
	id: 'Indonesia',
	ir: 'Iran',
	ie: 'Ireland',
	il: 'Israel',
	it: 'Italy',
	jm: 'Jamaica',
	jp: 'Japan',
	jo: 'Jordan',
	iq: 'Iraq',
	kz: 'Kazakhstan',
	ke: 'Kenya',
	kr: 'Korea',
	kw: 'Kuwait',
	kg: 'Kyrgyz Republic',
	la: "Lao People's Democratic Republic",
	lv: 'Latvia',
	lb: 'Lebanon',
	lr: 'Liberia',
	lt: 'Lithuania',
	lu: 'Luxembourg',
	ly: 'Libya',
	ma: 'Morocco',
	mo: 'Macao',
	mk: 'Macedonia',
	mg: 'Madagascar',
	mw: 'Malawi',
	my: 'Malaysia',
	ml: 'Mali',
	mv: 'Maldives',
	mt: 'Malta',
	mr: 'Mauritania',
	mu: 'Mauritius',
	mx: 'Mexico',
	fm: 'Micronesia',
	md: 'Moldova',
	mn: 'Mongolia',
	me: 'Montenegro',
	rw: 'Rwanda',
	ms: 'Montserrat',
	mm: 'Myanmar',
	mz: 'Mozambique',
	na: 'Namibia',
	nr: 'Nauru',
	np: 'Nepal',
	nl: 'Netherlands',
	nz: 'New Zealand',
	ni: 'Nicaragua',
	ne: 'Niger',
	ng: 'Nigeria',
	no: 'Norway',
	om: 'Oman',
	pk: 'Pakistan',
	pw: 'Palau',
	pa: 'Panama',
	pg: 'Papua New Guinea',
	py: 'Paraguay',
	pe: 'Peru',
	ph: 'Philippines',
	pl: 'Poland',
	pt: 'Portugal',
	ps: 'Palestine',
	qa: 'Qatar',
	ro: 'Romania',
	ru: 'Russia',
	xk: 'Kosovo',
	kn: 'Saint Kitts and Nevis',
	lc: 'Saint Lucia',
	vc: 'Saint Vincent and the Grenadines',
	st: 'Sao Tome and Principe',
	sa: 'Saudi Arabia',
	sn: 'Senegal',
	sy: 'Syrian Arab Republic',
	rs: 'Serbia',
	sc: 'Seychelles',
	sl: 'Sierra Leone',
	sg: 'Singapore',
	sk: 'Slovakia (Slovak Republic)',
	si: 'Slovenia',
	sb: 'Solomon Islands',
	sd: 'Sudan',
	za: 'South Africa',
	es: 'Spain',
	lk: 'Sri Lanka',
	sr: 'Suriname',
	sz: 'Swaziland',
	se: 'Sweden',
	ch: 'Switzerland',
	to: 'Tonga',
	tw: 'Taiwan',
	tj: 'Tajikistan',
	tz: 'Tanzania',
	th: 'Thailand',
	tt: 'Trinidad and Tobago',
	tn: 'Tunisia',
	tr: 'Turkey',
	tm: 'Turkmenistan',
	tc: 'Turks and Caicos Islands',
	ug: 'Uganda',
	ua: 'Ukraine',
	ae: 'United Arab Emirates',
	gb: 'United Kingdom',
	us: 'United States',
	uy: 'Uruguay',
	uz: 'Uzbekistan',
	ve: 'Venezuela',
	vn: 'Vietnam',
	vu: 'Vanuatu',
	ye: 'Yemen',
	zw: 'Zimbabwe',
	dj: 'Djibouti',
};

export const APP_PAGE_COUNTRY_CODES: ReadonlyArray<TCountryCode> = [
	'us',
	'gb',
	'ca',
	'au',
	'fr',
	'de',
	'it',
	'ru',
	'es',
	'br',
	'mx',
	'cn',
	'jp',
	'kr',
	'pt',
	'af',
	'al',
	'dz',
	'ao',
	'ai',
	'ag',
	'ar',
	'am',
	'at',
	'az',
	'bs',
	'bh',
	'bb',
	'by',
	'be',
	'bz',
	'bj',
	'bm',
	'bt',
	'bo',
	'ba',
	'bw',
	'bn',
	'bg',
	'bf',
	'kh',
	'cm',
	'cv',
	'ky',
	'td',
	'cl',
	'co',
	'ci',
	'cd',
	'cg',
	'cr',
	'hr',
	'cy',
	'cz',
	'dk',
	'dm',
	'do',
	'ec',
	'eg',
	'sv',
	'ee',
	'sz',
	'fj',
	'fi',
	'ga',
	'ge',
	'gm',
	'gh',
	'gr',
	'gd',
	'gt',
	'gw',
	'gy',
	'hn',
	'hk',
	'hu',
	'is',
	'in',
	'id',
	'iq',
	'ie',
	'il',
	'jm',
	'jo',
	'kz',
	'ke',
	'xk',
	'kw',
	'kg',
	'la',
	'lv',
	'lb',
	'ly',
	'lr',
	'lt',
	'lu',
	'mo',
	'mk',
	'mg',
	'mw',
	'my',
	'mv',
	'ml',
	'mt',
	'mr',
	'mu',
	'fm',
	'md',
	'mn',
	'me',
	'ms',
	'ma',
	'mz',
	'mm',
	'na',
	'nr',
	'np',
	'nl',
	'nz',
	'ni',
	'ne',
	'ng',
	'no',
	'om',
	'pg',
	'pk',
	'pw',
	'pa',
	'py',
	'pe',
	'ph',
	'pl',
	'qa',
	'ro',
	'rw',
	'kn',
	'lc',
	'vc',
	'st',
	'sa',
	'sn',
	'rs',
	'sc',
	'sl',
	'sg',
	'sk',
	'si',
	'sb',
	'za',
	'lk',
	'sr',
	'se',
	'ch',
	'tc',
	'tw',
	'tj',
	'tz',
	'to',
	'th',
	'tt',
	'tn',
	'tr',
	'tm',
	'ae',
	'ug',
	'ua',
	'uy',
	'uz',
	'vu',
	've',
	'vn',
	'vg',
	'ye',
	'zm',
	'zw',
];
