export type TFetchMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

export type TFetchResponse<T = unknown> = Readonly<{
	url: string;
	status: number;
	statusText: string;
	data: T;
}>;

export enum EHttpStatus {
	OK = 200,
	NoContent = 204,
	BadRequest = 400,
	Unauthorized = 401,
	NotFound = 404,
	Forbidden = 403,
	ValidationError = 422,
	ServerError = 500,
}

export type TFetchOptions = Readonly<{
	headers: Readonly<Record<string, string>>;
}>;
