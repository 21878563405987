import React from 'react';
import cn from 'classnames';

import {Image} from '@/_components/ui-kit/Image';

import css from './AppIcon.module.css';

interface IProps {
	src: string;
	title?: string;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
	className?: string;
	border?: boolean;
}

function AppIcon({src, title, size = 'md', className, border = true}: IProps) {
	return (
		<picture
			className={cn(
				css.root,
				size === 'sm' && css.sm,
				size === 'md' && css.md,
				size === 'lg' && css.lg,
				size === 'xl' && css.xl,
				size === 'xxl' && css.xxl,
				border && css.rootBorder,
				className,
			)}
		>
			<Image src={src} alt={title || 'App icon'} className={css.image} />
		</picture>
	);
}

export default React.memo<IProps>(AppIcon);
