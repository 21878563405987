import cn from 'classnames';
import React, {CSSProperties, forwardRef, Ref} from 'react';

import css from './ScrollWrap.module.css';

export interface IProps {
	children: React.ReactNode;
	className?: string;
	style?: CSSProperties;
	size?: 'sm' | 'md';
	onScroll?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
	sectionTestId?: string;
}

function ScrollWrap(
	{children, className, style, size = 'sm', sectionTestId, onScroll}: IProps,
	ref?: Ref<HTMLDivElement>,
) {
	return (
		<div
			ref={ref}
			style={style}
			className={cn(css.root, size === 'sm' && css.small, size === 'md' && css.medium, className)}
			data-testid={sectionTestId}
			onScroll={onScroll}
		>
			{children}
		</div>
	);
}

export default React.memo(forwardRef(ScrollWrap));
